import { FeatureConfigsService } from './feature/feature-configs.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { HttpClient } from '@angular/common/http';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PageLoadMessageService } from './common-services/page-load-message.service';
import { environment } from '../environments/environment';
import { AuthenticatorService } from './authorization/authenticator.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { domainUrlRegExp } from '../environments/domain';
import { ThemeService } from './themes/theme.service';
import { LoadBranding } from './ngrx/actions/branding.actions';
import { AppState } from './ngrx/state/index';
import { LoadReferenceData } from './ngrx/actions/reference-data.actions';
import { DomainService } from './authorization/domain.service';
import { PageAccessValidatorService } from './authorization/page-access-validator.service';

import { RumInit } from '@maersk-global/telemetry-web-sdk';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit,OnDestroy {

  title = 'app';
  showComponent = false;
  openedDrawer = true;
  urlAccessAllowed = true;
  private readonly _destroying$ = new Subject<void>();
  domainUrl: string;
  isIframe = false;
  accounts: AccountInfo[];
  activeAccount: AccountInfo | null;

  constructor(    
    private authService: MsalService,
    private router: Router, private translate: TranslateService,
    private pageLoadMessageService: PageLoadMessageService,
    private authenticatorService: AuthenticatorService,
    private ngxPermissionService: NgxPermissionsService,
    private pageAccessValidatorService: PageAccessValidatorService,
    private themeService: ThemeService,
    private store: Store<AppState>,
    private featureConfigsService: FeatureConfigsService,
    private domainService: DomainService,
    private httpClient: HttpClient,
    private msalBroadcastService: MsalBroadcastService,
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    this.domainUrl = this.domainService.currentDomain;

  }

  ngOnInit() {
    this.accounts = this.authService.instance.getAllAccounts();
    if(!this.accounts.length){
      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe(() => {
        this.authService.loginRedirect();
      })  
    }
    this.displayApplication();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  
    
    this.ngxPermissionService.permissions$.subscribe((permissions) => {
      this.activeAccount = this.authService.instance.getActiveAccount();
      if(this.activeAccount && Object.entries(permissions).length > 0){
        this.showComponent = true;
        this.initDataMopRum();
        if(!this.pageAccessValidatorService.validateUserAccess()){         
          this.urlAccessAllowed = false;
          this.router.navigate(['invalid/resource']);
        }
      }
    });
  }

  displayApplication() {
    if(this.accounts.length >0){
      this.fetchAndPreparePermissions();           
    }
  }

  fetchAndPreparePermissions() {
    this.authenticatorService.skipAuthentication = environment.skipAuthentication;
    this.authenticatorService.preparePermissions();
    this.store.dispatch(new LoadReferenceData());
  }

  

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    this.activeAccount = this.authService.instance.getActiveAccount();
    this.accounts = this.authService.instance.getAllAccounts();
    if (!this.activeAccount && this.accounts.length > 0) {
      this.activeAccount = this.accounts[0];
      this.authService.instance.setActiveAccount(this.activeAccount);
    }
  }

  loginRedirect() {
    this.authService.loginRedirect();
  }

  ngAfterViewInit() {   
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.pageLoadMessageService.changeMessage(true);
          this.initializeThemes(event);
        }
        if (event instanceof NavigationEnd ||
          event instanceof NavigationCancel) {
          this.pageLoadMessageService.changeMessage(false);
        }
      });
    if (!this.urlAccessAllowed) {
      this.router.navigate(['invalid/resource']);
    }
  }

  /**
   * initializeThemes() - Method to initialize the theme when any route changes
   */
  initializeThemes(event) {

    if (this.domainUrl.match(domainUrlRegExp.MAERSK)) {
      this.themeService.setMaerskTheme();
    } else if (this.domainUrl.match(domainUrlRegExp.SEALAND)) {
      this.themeService.setSealandTheme();
    } else if (this.domainUrl.match(domainUrlRegExp.HAS)) {
      this.themeService.setHamburgSudTheme();
      this.translate.use('has-en');
    } else {
      this.themeService.setDefaultTheme();
    }
    this.store.dispatch(new LoadBranding({
      branding: this.themeService.getActiveThemeProperties(),
      features: this.featureConfigsService.getEnabledFeature(this.themeService.getActiveThemeProperties())
    }));
  }

  /**
   * drawerToggle() - Method to ggle sidebar
   */
  drawerToggle() {
    this.openedDrawer = !this.openedDrawer;
  }

  initDataMopRum(){
    const { envName } = environment;
    const faroEnv = envName === 'bdd' ? 'cdt' : envName;
    const faro = RumInit({
      app: {
        name: `customer-allocation-tool-ui-${faroEnv}`,
        version: '0.0.56',
        environment: faroEnv
      },
      apiKey: '3YoRZgpGiIccr4GyhgfURM4SDjGMNA6vHb8ljSSKa957EB0zJGcZFPqP4VFLLw==', 
      useSendBeacon: true,
      debug: false, // false: Prevent showing log,error,etc captured in browser console
      traceLog: false, // Sends Traces to Loki ( For easy querying of traces)
      instrumentations: {
        interactions: {
          eventNames: ["click"]
        }
      }
    });
    const user = this.activeAccount;
    faro.api.setUser({
      id: user.localAccountId,
      username: user.name,
      email: user.username,
      attributes: {
        roles: user.idTokenClaims.roles?.join()
      }
    });
    faro.api.pushEvent(`Application Rum Init on ${faroEnv} with version ${faro.config.app.version} by ${user.username}`);  
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}